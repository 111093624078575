export default {
    "default_negotiations": [
        "self",
        "company_admin",
        "direct_chief",
        "senior_chief",
        "admin_tmk",
        "functional_chief",
        "company_admin",
        "admin_tmk"
    ],
    "stage_negotiations": {
        "intermediate_assessment": [
            "self",
            "company_admin"
        ]
    },
    "on_disagree_go_to_self_negotiation": false,
    "types": {
        "self": {
            "showName": "Сотрудник"
        },
        "admin_tmk": {
            "showName": "Администратор ТМК"
        },
        "direct_chief": {
            "showName": "Непосредственный руководитель"
        },
        "functional_chief": {
            "showName": "Функциональный руководитель"
        },
        "senior_chief": {
            "showName": "Вышестоящий руководитель"
        },
        "company_admin": {
            "showName": "Администратор предприятия"
        },
        "other": {
            "showName": "Прочее"
        }
    }
}