export default {
    "types": {
        "basic": {
            "showName": "Библиотека общекорпоративных КПЭ"
        },
        "personal": {
            "showName": "Библиотека персональных КПЭ"
        },
        "quality": {
            "showName": "Библиотека качественных КПЭ"
        }
    },
    "use_scales_units_library": true,
    "element_change_propagation_mode": 2,
    "folder_visibility_config_enabled": true,
    "folder_visibility_config_mode": 1,
    "folder_visibility_config_see_child": true,
    "on_import_using_visible": true,
    "on_folder_clone_clear_fields": [
        "bonus",
        "scale.fact"
    ],
    "elements_table_columns": [
        {
            "label": "Наименование КПЭ",
            "template": "components/tableColumnTemplates/library/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "show-folders-in-search": "templateData.$parent.$parent.$data.showFoldersInSearch"
            }
        },
        {
            "label": "Единица измерения",
            "prop": "scale.unit.symbol",
            "width": 100
        },
        {
            "if": "!templateData.modeSelect",
            "label": "Целевой уровень выполнения",
            "prop": "scale.target_level|numberFormat",
            "width": 200
        },
        {
            "label": "Шкала",
            "prop": "scale.parent.name",
            "width": 200
        },
        {
            "if": "!templateData.modeSelect",
            "label": "Факт выполнения",
            "prop": "scale.fact|numberFormat",
            "width": 100
        },
        {
            "if": "!templateData.modeSelect",
            "label": "% скорр",
            "prop": "scale.correction|numberFormat",
            "width": 100
        }
    ]
}