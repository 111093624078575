export default {
    "available_periods": {
        "12": "Год"
    },
    "available_sections_for_period": {
        "12": {
            "basic": {
                "selected": false
            },
            "personal_library": {
                "selected": false
            },
            "personal_manual": {
                "selected": false
            },
            "quality": {
                "selected": false
            }
        }
    },
    "cards_create_info_template": "componentsCompany/tmk/CardsCreateInfo",
    "medium_facts": {
        "available": false
    },
    "card_ar_history_behavior_ignore_fields": [
        "bonus",
        "has_changes_in_scale"
    ],
    "cards_visible_permissions_criteria_map": {
        "card.see_self": "app\\build\\tmk\\rbac\\criteria\\CardMyCriteria",
        "card.see_if_participate_insurance": "app\\build\\tmk\\rbac\\criteria\\CardIParticipateByInsuranceCriteria",
        "card.see_if_participate": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardIParticipateCriteria",
        "card.see_subordinates": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardAllSubordinatesCriteria",
        "card.see_chiefs": "app\\domain\\Card\\criteria\\returnInNewQuery\\CardMyChiefsCriteria"
    },
    "cards_page_filters": {
        "card_search_request_class": "app\\build\\tmk\\card\\criteria\\CardSearchRequest",
        "template": "componentsCompany/tmk/CardsPageFilters",
        "search_fields": {
            "card_info.last_name": {
                "relation": "info"
            },
            "card_info.middle_name": {
                "relation": "info"
            },
            "card_info.first_name": {
                "relation": "info"
            },
            "card_info.position": {
                "relation": "info"
            },
            "card_info.company_division": {
                "relation": "info"
            },
            "card_info.company": {
                "relation": "info"
            }
        },
        "default_sort_params": {
            "attributes": {
                "0": "year",
                "info.full_name": {
                    "asc": {
                        "card_info.last_name": 4
                    },
                    "desc": {
                        "card_info.last_name": 3
                    }
                },
                "info.company": {
                    "asc": {
                        "card_info.company": 4
                    },
                    "desc": {
                        "card_info.company": 3
                    }
                },
                "currentStage.currentNegotiation.user.full_name currentUser": {
                    "asc": {
                        "currentUser.last_name": 4
                    },
                    "desc": {
                        "currentUser.last_name": 3
                    }
                }
            },
            "defaultOrder": {
                "year": 3,
                "currentStage.currentNegotiation.user.full_name currentUser": 4,
                "info.full_name": 4
            }
        }
    },
    "cards_page_columns": [
        {
            "label": "Год",
            "prop": "year",
            "width": 70
        },
        {
            "label": "Тип",
            "template": "componentsCompany/tmk/tableColumnTemplates/cards/TypeColumnTemplate",
            "width": 50
        },
        {
            "label": "ФИО",
            "template": "componentsCompany/tmk/tableColumnTemplates/cards/RouterLinkToCardColumnTemplate",
            "prop": "info.full_name"
        },
        {
            "label": "Должность",
            "prop": "info.position"
        },
        {
            "width": 300,
            "label": "Подразделение",
            "prop": "info.company_division"
        },
        {
            "label": "Предприятие",
            "prop": "info.company"
        },
        {
            "width": 100,
            "label": "% выплат",
            "prop": "bonus|numberFormat"
        },
        {
            "label": "Этап",
            "template": "components/tableColumnTemplates/cards/CurrentNegotiationColumnTemplate"
        },
        {
            "label": "Ответственный за этап",
            "template": "components/tableColumnTemplates/cards/CurrentNegotiationUserColumnTemplate"
        },
        {
            "label": "",
            "width": 40,
            "template": "componentsCompany/tmk/tableColumnTemplates/cards/StatusColumnTemplate"
        },
        {
            "template": "components/tableColumnTemplates/cards/ActionsColumnTemplate",
            "width": 80,
            "align": "right",
            "on": {
                "delete-card": "(e) => { templateData.$emit(\"delete-card\", e) }"
            }
        }
    ],
    "card_header_template": "componentsCompany/tmk/CardHeader",
    "card_header_elements": [],
    "card_selectable_page_columns": []
}