export default {
    "sectionsBlockHeaderTemplate": "componentsCompany/tmk/CardSectionsBlockHeader",
    "sections": {
        "basic": {
            "showName": "Общекорпоративные показатели",
            "emptyText": "Нет целей",
            "fromLibrary": "basic",
            "fieldsAvailableForEdit": {
                "comment.plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ]
                },
                "scale.weight_plan": {
                    "stages": [
                        "plan"
                    ],
                    "permissions": [
                        "card_element.update_in_basic_section"
                    ]
                }
            },
            "createElementBusinessRulePermissions": [
                "card_element.create_in_basic_section"
            ]
        },
        "personal_library": {
            "showName": "Персональные показатели из библиотеки",
            "emptyText": "Нет целей",
            "fromLibrary": "personal",
            "fieldsAvailableForEdit": {
                "comment.plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ]
                },
                "scale.weight_plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.fact": {
                    "stages": [
                        "assessment"
                    ]
                },
                "scale.forecast": {
                    "stages": [
                        "intermediate_assessment"
                    ]
                },
                "scale.correction": {
                    "stages": [
                        "assessment"
                    ],
                    "permissions": [
                        "card_element.edit_field_correction"
                    ]
                }
            }
        },
        "personal_manual": {
            "showName": "Персональные показатели не из библиотеки",
            "emptyText": "Нет целей",
            "fieldsAvailableForEdit": {
                "comment.plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ]
                },
                "name": {
                    "stages": [
                        "plan"
                    ]
                },
                "desc": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.unit_id": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.target_level": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.weight_plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.parent_id": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.forecast": {
                    "stages": [
                        "intermediate_assessment"
                    ]
                },
                "scale.fact": {
                    "stages": [
                        "assessment"
                    ]
                },
                "scale.correction": {
                    "stages": [
                        "assessment"
                    ],
                    "permissions": [
                        "card_element.edit_field_correction"
                    ]
                }
            }
        },
        "quality": {
            "showName": "Качественная оценка",
            "emptyText": "Нет целей",
            "fromLibrary": "quality",
            "fieldsAvailableForEdit": {
                "comment.plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "comment.assessment": {
                    "stages": [
                        "assessment"
                    ]
                },
                "name": {
                    "stages": [
                        "plan"
                    ]
                },
                "desc": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.unit_id": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.target_level": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.weight_plan": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.parent_id": {
                    "stages": [
                        "plan"
                    ]
                },
                "scale.forecast": {
                    "stages": [
                        "intermediate_assessment"
                    ]
                },
                "scale.fact": {
                    "stages": [
                        "assessment"
                    ]
                },
                "scale.correction": {
                    "stages": [
                        "assessment"
                    ],
                    "permissions": [
                        "card_element.edit_field_correction"
                    ]
                }
            }
        }
    },
    "default_columns": [
        {
            "label": "№",
            "width": 40,
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementOrderTableColumn",
            "bind": {
                "scope": "scope"
            }
        },
        {
            "label": "Наименование показателя",
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Единица измерения",
            "width": 90,
            "template": "components/tableColumnTemplates/card/ElementLibraryUnitTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Целевой уровень выполнения",
            "width": 130,
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementTargetLevelTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Вес КПЭ (план)",
            "width": 80,
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementWeightPlanTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Шкала",
            "width": 140,
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementScaleTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий по цели",
            "if": "templateData.$canAndRulesPass(templateData.card, templateData.$permissions.COMMENT.SEE)",
            "width": 110,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'plan'",
                "last-comment": "scope.row.last_comment_by_stage['plan']",
                "comments-count": "scope.row.comments_count_by_stage['plan']"
            }
        },
        {
            "label": "Промежуточное выполнение",
            "width": 120,
            "showOnStages": [
                "intermediate_assessment",
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementForecastTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "пром. % вып. КПЭ",
            "showOnStages": [
                "intermediate_assessment"
            ],
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementForecastPercentTableColumn",
            "bind": {
                "scope": "scope"
            },
            "width": 80
        },
        {
            "label": "Факт выполнения",
            "width": 120,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementFactTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "% вып. КПЭ",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "prop": "scale.percent|numberFormat",
            "width": 70
        },
        {
            "label": "% вып. по шкале",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "prop": "scale.scale_percent|numberFormat",
            "width": 80
        },
        {
            "label": "Вес КПЭ (факт)",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "prop": "scale.weight_fact|numberFormat",
            "width": 80
        },
        {
            "label": "% скорр.",
            "width": 80,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementCorrectionTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Итог",
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "prop": "bonus|numberFormat",
            "width": 80
        },
        {
            "label": "Комментарий по выполнению",
            "if": "templateData.$canAndRulesPass(templateData.card, templateData.$permissions.COMMENT.SEE)",
            "width": 120,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "components/tableColumnTemplates/card/ElementCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment'",
                "last-comment": "scope.row.last_comment_by_stage['assessment']",
                "comments-count": "scope.row.comments_count_by_stage['assessment']"
            }
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ]
}