<template>
  <div>
    <div class="folders-tree-node" style="margin-bottom: 10px; font-weight: bold; font-size:17px; align-items: unset;">
      <span>Группы целей</span>
      <el-tooltip
        v-if="!modeSelect && $canAndRulesPass(user, $permissions.LIBRARY.FOLDER_CREATE)"
        content="Добавить группу"
        placement="top"
        :open-delay="500"
      >
        <el-button
          style="margin-left: 0px;background-color: unset"
          class="table-action-button"
          type="text"
          @click="editFolderModalShow({}, true)"
          icon="fas fa-plus-circle"
        >
        </el-button>
      </el-tooltip>
      <library-import-elements-modal
        v-if="$canAndRulesPass(user, $permissions.LIBRARY.FOLDER_IMPORT)"
      >
        <el-tooltip
          slot="reference"
          content="Импорт файла"
          placement="top"
          :open-delay="500"
        >
          <el-button
            style="margin-left: 0px;background-color: unset"
            class="table-action-button"
            type="text"
            icon="fas fa-file-import"
            @click=""
          >
          </el-button>
        </el-tooltip>
      </library-import-elements-modal>

    </div>
    <el-tree
      v-if="showTree"
      empty-text="Нет групп"
      :load="loadTreeNode"
      lazy
      highlight-current
      ref="foldersTree"
      node-key="id"
      :expand-on-click-node="true"
      v-loading="loadingRootFolder"
      @node-click="folderClicked"
    >
      <span class="folders-tree-node" slot-scope="{ node, data }">
        <span class="icon-folder">
          <i v-if="!node.expanded" class="fas fa-folder" aria-hidden="true"></i>
          <i v-if="node.expanded" class="fas fa-folder-open" aria-hidden="true"></i>
          <span class="icon-folder_text" style="padding-left:5px">{{ data.name }}</span>
        </span>
          <span class="manageFolderButtonsSpan" v-if="!modeSelect">
            <el-tooltip
              v-if="$canAndRulesPass(data, $permissions.LIBRARY.FOLDER_EXPORT)"
              content="Экспорт в XLSX"
              placement="top"
              :open-delay="500"
            >
                <el-button
                  style="margin-left: 0px;background-color: unset"
                  class="table-action-button"
                  type="text"
                  icon="fas fa-file-excel"
                  @click="exportToExcel(node)"
                >
                </el-button>
            </el-tooltip>
            <el-button
              v-if="$canAndRulesPass(data, $permissions.LIBRARY.FOLDER_UPDATE)"
              style="background-color: unset; padding:0"
              class="table-action-button"
              type="text"
              @click="editFolderModalShow(node)"
              icon="fas fa-pen"
            >
            </el-button>
            <el-tooltip
              v-if="$canAndRulesPass(data, $permissions.LIBRARY.FOLDER_CREATE)"
              content="Добавить подгруппу"
              placement="top"
              :open-delay="500"
            >
                <el-button
                  style="margin-left: 0px;background-color: unset"
                  class="table-action-button"
                  type="text"
                  @click="editFolderModalShow(node, true)"
                  icon="fas fa-plus-circle"
                >
                </el-button>
            </el-tooltip>
          </span>
      </span>
    </el-tree>

    <el-dialog
      v-if="!modeSelect"
      title="Редактирование группы"
      :visible.sync="editFolderModal.show"
      width="50%"
    >
      <el-row>
        <el-form
          size="small"
          label-position="top"
        >
          <el-form-item label="Название группы">
            <el-input maxlength="255" show-word-limit v-model="editFolderModal.folder.name"></el-input>
          </el-form-item>

          <el-row
            v-if="!editFolderModal.folder.parent_id"
            type="flex"
            justify="space-between"
          >
            <el-col :span="7">
              <el-form-item label="Год" prop="year">
                <el-date-picker
                  v-model="editFolderModal.folder.year"
                  style="width:100%"
                  type="year"
                  value-format="yyyy"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col
              v-show="!(Object.keys($companyConfiguration.card.getAvailablePeriods()).length === 1)"
              :span="7"
            >
              <el-form-item label="Период оценки" prop="period">
                <el-select
                  v-model="editFolderModal.folder.period"
                  style="width:100%"
                >
                  <el-option
                    v-for="(cardPeriodName, cardPeriodLength) in $companyConfiguration.card.getAvailablePeriods()"
                    :key="cardPeriodLength"
                    :label="cardPeriodName"
                    :value="+cardPeriodLength"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col
              v-show="!(availablePeriodIndexes === 1) || !(Object.keys($companyConfiguration.card.getAvailablePeriods()).length === 1)"
              :span="7"
            >
              <el-form-item label="Номер периода" prop="period_index">
                <el-select
                  v-model="editFolderModal.folder.period_index"
                  no-data-text="Выберите период"
                  style="width:100%"
                >
                  <el-option
                    v-for="index in availablePeriodIndexes"
                    :key="index"
                    :label="index"
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>


          <el-form-item label="Модераторы" style="margin-top:30px">
            <users-table
              add-button-text="Добавить модератора"
              add-users-filter-role=""
              :tool-row-style="{marginTop:-30+'px'}"
              :users.sync="editFolderModal.folder.users"
            ></users-table>
          </el-form-item>



          <el-form-item
            v-if="$companyConfiguration.libraries.getFolderVisibilityConfigEnabled() && $canAndRulesPass(editFolderModal.folder, $permissions.LIBRARY.FOLDER_EDIT_VQB)"
            label="Доступ для пользователей"
            style="margin-top:30px"
          >

            <entity-saved-report-form
              style="margin-top: -30px"
              :entity-type="$constants.visualQueryBuilder.formEntityTypes.TYPE_LIBRARY_FOLDER"
              :entity-id="editFolderModal.folder.id"
              entity-report-key="users"
              delete-on-empty-search
            ></entity-saved-report-form>
          </el-form-item>



        </el-form>
      </el-row>


      <div slot="footer" class="dialog-footer">
        <el-popconfirm
          v-if="$canAndRulesPass(editFolderModal.folder, $permissions.LIBRARY.FOLDER_DELETE)"
          style="float:left"
          v-show="editFolderModal.folder.id"
          confirm-button-text='Да'
          cancel-button-text='нет'
          icon="el-icon-question"
          title="Вы уверены, что хотите удалить группу?"
          @confirm="deleteFolder"
        >
          <el-button
            slot="reference"
            type="danger"
            size="small"
            plain
            icon="fas fa-trash"
            :loading="editFolderModal.loading"
          >
            Удалить группу
          </el-button>
        </el-popconfirm>
        <el-popconfirm
          v-if="$canAndRulesPass(user, $permissions.LIBRARY.FOLDER_DUPLICATE)"
          style="float:left;margin-left: 10px;"
          v-show="editFolderModal.folder.id && !editFolderModal.folder.parent_id"
          confirm-button-text='Да'
          cancel-button-text='нет'
          icon="el-icon-question"
          title="Вы уверены, что хотите клонировать группу?"
          @confirm="duplicateFolder"
        >
          <el-button
            slot="reference"
            size="small"
            icon="fas fa-copy"
            :loading="editFolderModal.loading"
          >
            Клонировать группу
          </el-button>
        </el-popconfirm>
        <el-button size="small" @click="editFolderModal.show = false">Отмена</el-button>
        <el-button
          type="primary"
          size="small"
          :loading="editFolderModal.loading"
          :disabled="!editFolderModal.folder.name"
          @click="saveFolder"
        > Сохранить
        </el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import UsersTable from "../user/UsersTable";
import {downloader} from "@/mixins/downloader";
import {mapGetters} from "vuex";
import LibraryImportElementsModal from "@/components/library/LibraryImportElementsModal.vue";
import EntitySavedReportForm from "@/components/report/builder/EntitySavedReportForm.vue";

export default {
  name: "library-folders",
  components: {EntitySavedReportForm, LibraryImportElementsModal, UsersTable},
  mixins: [downloader],

  props: {
    libraryType: {
      type: String,
      required: true
    },
    modeSelect: {type: Boolean, default: false},
    fromCard: { type: Number },
  },

  computed: {
    ...mapGetters(['user']),
    availablePeriodIndexes(){
      if( !this.editFolderModal.folder.period ){
        return 0;
      }
      return 12/this.editFolderModal.folder.period;
    },
  },
  data() {
    return {

      loadingRootFolder: false,
      showTree: true,

      editFolderModal: {
        show: false,
        loading: false,
        folder: {
          id: null,
          name: null,
          parent_id: null,
          year: '',
          period: null,
          period_index: null,
          users: [],
        },
        node: null,
      }
    }
  },
  mounted() {

  },
  methods: {
    loadTreeNode(node, resolve) {
      this.loadingRootFolder = !node.data;
      this.$api.library.getFoldersByParent({
        library_type: this.libraryType,
        parent_id: node.data ? node.data.id : null,
        select_mode: this.modeSelect ? 1 : null,
        from_card: this.fromCard ? this.fromCard : null,
        expand: [
          'users',
          (this.modeSelect ? null : 'permissions'),
        ].join(),
      })
        .then(data => {
          this.loadingRootFolder = false;
          return resolve(data.folders)
        })
    },
    folderClicked(node, nodeOfTree, treeNode) {
      this.$emit('folder-selected', node);
    },

    editFolderModalShow(node, isNew = false) {
      if (!node) {
        node = this.$refs.foldersTree.root;
      }
      this.editFolderModal.node = node;
      this.editFolderModal.show = true;
      if (isNew) {
        this.editFolderModal.folder.id = null;
        this.editFolderModal.folder.name = null;
        this.editFolderModal.folder.year = null;
        this.editFolderModal.folder.period =
          Object.keys(this.$companyConfiguration.card.getAvailablePeriods()).length === 1
            ? +Object.keys(this.$companyConfiguration.card.getAvailablePeriods())[0]
            : null;
        this.editFolderModal.folder.period_index =
          this.availablePeriodIndexes === 1
            ? 1
            : null;
        this.editFolderModal.folder.users = [];
        this.editFolderModal.folder.parent_id = node.data ? node.data.id : null;
      } else {
        this.editFolderModal.folder = Object.assign({}, node.data);
        this.editFolderModal.folder.year = this.editFolderModal.folder.year ? this.editFolderModal.folder.year.toString() : null;
      }
    },

    deleteFolder() {
      if (!this.editFolderModal.folder.id) {
        return;
      }
      this.$confirm('Показатели из группы могут присутствовать в элементах карт. Вы действительно хотите удалить группу? Будут удалены все элементы в картах', 'Внимание', {
        confirmButtonText: 'Да, удалить',
        cancelButtonText: 'нет',
        type: 'warning'
      }).then(() => {
        this.editFolderModal.loading = true;
        this.$api.library.deleteFolder({
          id: this.editFolderModal.folder.id,
        })
          .then(data => {
            this.$notify.success({
              title: 'Сохранено',
              message: 'Группа успешно удалена'
            });
          })
          .finally(() => {
            this.editFolderModal.show = false;
            this.editFolderModal.loading = false;
            this.editFolderModal.node.parent.loaded = false;
            this.editFolderModal.node.parent.expand();
          })
      });

    },
    saveFolder() {
      this.editFolderModal.loading = true;
      let user_ids = this.editFolderModal.folder.users.map(user => user.id)
      this.$api.library.saveFolder({
        id: this.editFolderModal.folder.id,
        library_type: this.libraryType,
        parent_id: this.editFolderModal.folder.parent_id,
        name: this.editFolderModal.folder.name,
        year: this.editFolderModal.folder.year,
        period: this.editFolderModal.folder.period,
        period_index: this.editFolderModal.folder.period_index,
        user_ids: user_ids,
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Группа успешно сохранена'
          });
        })
        .finally(() => {
          this.editFolderModal.loading = false;
          this.editFolderModal.show = false;
          let node =
            this.editFolderModal.folder.id
              ? this.editFolderModal.node.parent // Значит, что это не новая папка, нужно обновлять родителя
              : this.editFolderModal.node;      // Новая папка внутри папки. Значит нужно обновить саму ноду

          if( node.expand ){
            node.loaded = false;
            node.expand();
          } else {
            // Для новой рутовой папки перерисовываем папки
            this.showTree = false;
            this.$nextTick(() => {
              this.showTree = true;
            })
          }

        })
    },
    duplicateFolder() {
      if (!this.editFolderModal.folder.id || this.editFolderModal.folder.parent_id) {
        return;
      }
      this.editFolderModal.loading = true;
      this.$api.library.duplicateFolder({
        id: this.editFolderModal.folder.id,
      })
        .then(data => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Группа успешно клонирована'
          });
        })
        .finally(() => {
          this.editFolderModal.show = false;
          this.editFolderModal.loading = false;
          this.editFolderModal.node.parent.loaded = false;
          this.editFolderModal.node.parent.expand();
        })
    },


    exportToExcel(node) {
      this.downloadFile(
        'export.xlsx',
        this.$api.library.exportFolder,
        {id: node.data.id}
      )
        .finally(() => {
          // this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.folders-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*font-size: 14px;*/
  /*padding-right: 8px;*/
}

.folders-tree-node .manageFolderButtonsSpan {
  display: none;
}

.folders-tree-node:hover .manageFolderButtonsSpan {
  display: block;
}
</style>