export default {
    "individualProps": [
        "weight_fact"
    ],
    "summary_template": "componentsCompany/tmk/matrix/MatrixSummary",
    "default_columns": [
        {
            "label": "Наименование показателя",
            "width": 250,
            "template": "components/tableColumnTemplates/card/ElementNameTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "Вес КПЭ (план)",
            "width": 90,
            "manualColumnTemplate": true,
            "template": "components/tableColumnTemplates/matrix/MatrixWeightTableColumn",
            "bind": {
                "cards": "templateData.cards",
                "cardsWeights": "templateData.cardsWeights",
                "scope": "templateData"
            }
        },
        {
            "label": "Единица измерения",
            "width": 90,
            "template": "components/tableColumnTemplates/card/ElementLibraryUnitTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "Целевой уровень выполнения",
            "width": 130,
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementTargetLevelTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "Шкала",
            "width": 140,
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementScaleTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "Промежуточное выполнение",
            "width": 120,
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementForecastTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "Факт выполнения",
            "width": 120,
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementFactTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "% вып. КПЭ",
            "prop": "scale.percent|numberFormat",
            "width": 70
        },
        {
            "label": "% вып. по шкале",
            "prop": "scale.scale_percent|numberFormat",
            "width": 80
        },
        {
            "label": "Вес КПЭ (факт)",
            "width": 90,
            "manualColumnTemplate": true,
            "template": "componentsCompany/tmk/matrix/MatrixWeightFactTableColumn",
            "bind": {
                "cards": "templateData.cards",
                "cardsWeights": "templateData.cardsWeights",
                "scope": "templateData"
            }
        },
        {
            "label": "% скорр.",
            "width": 80,
            "template": "componentsCompany/tmk/tableColumnTemplates/card/ElementCorrectionTableColumn",
            "bind": {
                "scope": "scope",
                "is-matrix": true
            }
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/matrix/MatrixActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "align": "'center'",
            "width": 50
        }
    ]
}