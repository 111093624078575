<template>
  <div v-loading="loading">
    <el-upload
      ref="file-uploader"
      class=""
      action=""
      :limit="2"
      :file-list="fileList"
      :on-change="changeFileList"
      :auto-upload="true"
      :show-file-list="false"
      :http-request="doFileUpload"
    >
      <el-button size="small" type="primary">Выберите файл для загрузки</el-button>
    </el-upload>

<!--    <el-button size="small" type="primary" @click="doPreview">Првью</el-button>-->
    <div
      v-if="fileList.length"
      style="margin-top:10px"
    >Загружено пользователей: {{ users.length }}</div>

  </div>
</template>

<script>

export default {
  name: "load-users-from-excel",
  mixins: [],

  props: {},

  data() {
    return {
      loading: false,
      fileList: [],
      users: [],
    }
  },

  methods: {

    changeFileList() {
      this.fileList = this.$refs["file-uploader"].uploadFiles
      if(this.fileList.length === 2) this.fileList.splice(0, 1)
    },


    doFileUpload() {
      this.users = [];
      this.loading = true;

      let form = new FormData();
      form.append('file', this.fileList[0].raw);
      // form.append('preview', '1');

      return this.$api.user.selectionByExcel(form)
        .then(data => {
          // this.users = data.map(userId => { return {id: userId}})
          this.users = data.users;
          this.$emit('users-loaded', this.users);
        })
        .finally(() => {
          this.loading = false;

        })
    },
  }
}
</script>

<style>

</style>
