
const checkPermissionDataAvailable = (object, permission) => {
    if( !object ) return false;
    if( !object.permissions ) return false;
    if( !object.permissions[permission] ) return false;

    return true;
}


const can = (object, permission) => {
    if( !checkPermissionDataAvailable(object, permission) ) {
        return false;
    }
    return object.permissions[permission].can
}

const rulesPass = (object, permission) => {
    if( !checkPermissionDataAvailable(object, permission) ) {
        return false;
    }
    return object.permissions[permission].rulesPass
}

const canAndRulesPass = (object, permission) => {
    return can(object, permission) && rulesPass(object, permission);
}


const permissions = {
    CARD: {
        CREATE: 'card.create',
        UPDATE_INFO: 'card.update_info',
        EXPORT: 'card.export',
        SEE_COMMENTS: 'card.see_comments',
        LIST: 'card.list',
        LIST_DELETED: 'card.list_deleted',
    },
    CARD_STAGE: {
        CREATE_DYNAMIC: 'card_stage.create_dynamic',
        SET_DATE: 'card_stage.set_date',
        DELETE: 'card_stage.delete',
    },
    CARD_STAGE_NEGOTIATION: {
        CREATE: 'card_stage_negotiation.create',
        UPDATE: 'card_stage_negotiation.update',
        DELETE: 'card_stage_negotiation.delete',
        MOVE: 'card_stage_negotiation.move',
        SET_AS_CURRENT: 'card_stage_negotiation.set_as_current',
    },
    CARD_ELEMENT: {
        CREATE: 'card_element.create',
        UPDATE: 'card_element.update',
        DELETE: 'card_element.delete',
    },
    COMMENT: {
        SEE: 'comment.see',
        CREATE: 'comment.create',
        DELETE: 'comment.delete',
    },
    GROUP: {
        LIST: 'group.list',
        CREATE: 'group.create',
        UPDATE: 'group.update',
        DELETE: 'group.delete',
    },
    SETTING: {
        LIST: 'setting.list',
        UPDATE: 'setting.update',
    },
    RBAC: {
        LIST: 'rbac.list',
        SET_PERMISSION: 'rbac.set_permission',
        ROLE_CREATE: 'rbac.role_create',
        ROLE_UPDATE: 'rbac.role_update',
        ROLE_DELETE: 'rbac.role_delete',
        MANAGE_USERS: 'rbac.manage_users',
    },
    LIBRARY: {
        SEE_IN_MENU: 'library.see_in_menu',

        FOLDER_LIST: 'library.folder_list',
        FOLDER_CREATE: 'library.folder_create',
        FOLDER_UPDATE: 'library.folder_update',
        FOLDER_DELETE: 'library.folder_delete',
        FOLDER_DUPLICATE: 'library.folder_duplicate',
        FOLDER_EXPORT: 'library.folder_export',
        FOLDER_IMPORT: 'library.folder_import',
        FOLDER_EDIT_VQB: 'library.folder_edit_vqb',

        ELEMENT_LIST: 'library.element_list',
        ELEMENT_CREATE: 'library.element_create',
        ELEMENT_UPDATE: 'library.element_update',
        ELEMENT_DELETE: 'library.element_delete',
        ELEMENT_SEE_HISTORY: 'library.element_see_history',
    },
    REPORT: {
        USE: 'report.use',
    },
    MATRIX: {
        VIEW: 'matrix.view',
    },
    MASS_ACTIONS: {
        CREATE: 'mass_actions.create',
    },
    USER: {
        // SEE_HOME_CHARTS: 'user.see_home_charts'
        ORGSTRUCT_VIEW: 'user.orgstruct_view',
        CHIEFS_LIST_BY_TYPE: 'user.chiefs_list_by_type',
        CHIEFS_SET: 'user.chiefs_set',
    },
    CARD_CREATE_TEMPLATES: {
        LIST: 'card_create_template.list',
        UPDATE: 'card_create_template.update',
        DELETE: 'card_create_template.delete',
    },
}

export default {
    install(Vue) {
        Vue.prototype.$permissions = permissions;
        Vue.prototype.$can = can;
        Vue.prototype.$rulesPass = rulesPass;
        Vue.prototype.$canAndRulesPass = canAndRulesPass;
    }
}