export default {
    "enabled_auth_methods": [
        "loginPass",
        "openId"
    ],
    "auth_methods": {
        "loginPass": [],
        "openId": {
            "domain": "https://dev-op10ami2rb7v24hh.us.auth0.com",
            "clientId": "BSwbJBdds155uVaZ4OeRFGj5A9O9yILa",
            "redirectUri": "",
            "service": "app\\build\\tmk\\auth\\services\\AuthOpenIdService",
            "authField": "insurance"
        }
    }
}