export default {
    "types": {
        "plan": {
            "showName": "Постановка",
            "selected": false,
            "order": 1,
            "autostart": false,
            "canManageElementsBeforeDateStart": false,
            "canManageElementsAfterDateEnd": true,
            "hideDates": true
        },
        "intermediate_assessment": {
            "showName": "Промежуточная оценка",
            "selected": false,
            "order": 2,
            "canManageElementsBeforeDateStart": false,
            "canManageElementsAfterDateEnd": true,
            "hideDates": true
        },
        "assessment": {
            "showName": "Оценка",
            "selected": false,
            "order": 3,
            "canManageElementsBeforeDateStart": false,
            "canManageElementsAfterDateEnd": true,
            "hideDates": true
        }
    }
}